.title__mobile {
    font-size: var(--font-size-titles-sm);
    font-weight: var(--font-weight-medium);
    line-height: normal
}

.title__desktop {
    font-size: var(--font-size-titles-md);
    font-weight: var(--font-weight-medium);
}

.subtitle {
    font-size: var(--font-size-titles-xs);
    font-weight: var(--font-weight-normal);
    line-height: normal;
    text-wrap: balance;
}