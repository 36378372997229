.borderBottom {
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
  max-width: 659px !important;
}

.transitionToOpen {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.transitionToClose {
  transform: rotate(0);
  transition: transform 0.5s ease;
}