.dayOptions___container {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.dayOptions___container::-webkit-scrollbar {
  display: none;
}

.custom__top {
  top: auto !important;
}

@media screen and (max-width: 39.9375em) {
  .dayOptions___container {
    gap: 8px;
    padding-right: 16px;
  }
}
