.link,
.disabled {
  display: flex;
  text-decoration: none;
  color: var(--color-text-black);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
}

.link > a {
  text-decoration: none;
  margin-right: 8px;
  font-weight: var(--font-weight-medium);
}

.link > svg > path,
.link > svg > g {
  stroke: var(--color-text-black);
}

.link:hover,
.link:active,
.link:hover > svg > path,
.link:hover > svg > g,
.link:active > svg > path,
.link:active > svg > g,
.link > svg:hover > g,
.link > svg:hover > path,
.link > svg:active > g,
.link > svg:active > path {
  color: var(--color-primary-light-red);
  stroke: var(--color-primary-light-red);
}

.disabled {
  color: var(--color-primary-disabled);
}

.disabled > svg > path,
.disabled > svg > g {
  stroke: var(--color-primary-disabled);
}

.disabled > a {
  margin-right: 8px;
  text-decoration: none;
}

.borderRight {
  border-right: 0.3px solid var(--color-neutral-gray-divider);
}
