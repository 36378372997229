.customHeight{
    min-height: calc(100vh - 223px) !important;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
    .customHeight{
        min-height: initial !important;
    }

    .focusBtn__mobile:active, .focusBtn__mobile:focus, .focusBtn__mobile:hover {
        background-color: var(--color-base-black) !important;
    }
}