.optionSelected {
  border-color: var(--color-primary-light-red) !important
}

.minHeightMobile {
  min-height: calc(100vh - 76px) !important;
}

.loadingOverlay {
  background-color: rgba(255, 255, 255, 0.70);
  height: 100% !important;
  left: 0;
  right: 0;
  top: 0;
  width: 100% !important;
  z-index: var(--z-index-1);
}