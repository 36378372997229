.modalContainer {
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
}

.modalContainer article {
  position: fixed;
  top: 0;
  right: -100%;
  transition: right 0.5s ease;
}

.modalContainer > :nth-child(2) {
  border-radius: var(--border-radius-md) !important;
  margin: 10px;
  min-height: calc(100vh - 20px) !important;
}

.customHeader {
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
}

.customBorderTop {
  border-top: 0.3px solid var(--color-neutral-gray-divider);
}

.customTab {
  top: -1px;
  background-color: var(--color-base-white);
  min-width: 100% !important;
  position: sticky !important;
  margin-bottom: 8px;
  z-index: var(--z-index-5);
}

.customTab ul {
  background-color: var(--color-base-white);
  min-width: 100%;
}

.customTab ul li {
  background-color: var(--color-base-white);
  min-width: 50%;
}

.customTab li div {
  align-items: center;
  justify-content: center;
  padding: 24px 16px 14px !important;
}

.oneTab ul li {
  border-bottom: 0;
}

.oneTab ul li div {
  justify-content: flex-start;
}

.containerModal {
  position: fixed;
  top: 0;
  right: -100%; /* Hide initially off-screen to the right */
  transition: right 0.5s ease; /* Animation for show modal */
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.openModal {
  right: 0 !important; /* Moves the modal to the left, inside the screen */
}

.closeModal {
  right: -100% !important; /* Moves the modal to the right, out of the screen */
}