.wrapper__carouselContainer {
  padding: 20px 0 20px 8px;
}

.wrapper__carouselContainer > ul {
  grid-gap: 15px;
  transform: none !important;
}

.wrapper__carouselContainer > ul > li {
  border-radius: 0;
  max-width: 82px;
  min-width: 80px;
}

.transitionToHideBorder {
  transition: border 0.3s ease-out;
  border: 0.6px solid var(--color-base-transparent);
}

.transitionToShowBorder {
  transition: border 0.3s ease-in;
  border: 0.6px solid var(--color-neutral-gray-divider);
}

@media screen and (max-width: 39.9375em) {
  .wrapper__carouselContainer {
    padding: 15px 0px 15px 8px;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .wrapper__carouselContainer::-webkit-scrollbar {
    display: none;
  }

  .wrapper__carouselContainer > ul {
    grid-gap: 10px;
    transition: none !important;
  }

  .wrapper__carouselContainer > ul > li {
    max-width: 50px;
    min-width: 50px;
  }
}